const filterButton = document.getElementById('filter-button');
const filterModal = document.getElementById('filter-modal');
const cancelButton = document.getElementById('cancel-filter');
const regionSelect = document.querySelector('select[name="region[id]"]');
const citySelect = document.querySelector('select[name="city[id]"]');

const updateCities = async (regionName) => {
  if (!regionName) {
    citySelect.innerHTML = '<option value="">Please select a region first</option>';
    citySelect.disabled = true;
    return;
  }

  try {
    const response = await fetch(`/regions/${encodeURIComponent(regionName)}/cities`);
    const cities = await response.json();

    citySelect.innerHTML = '<option value="">Select a city</option>';
    cities.forEach(city => {
      const option = new Option(city.name, city.name);
      citySelect.add(option);
    });
    citySelect.disabled = false;
  } catch (error) {
    console.error('Error fetching cities:', error);
    citySelect.disabled = true;
  }
};

regionSelect.addEventListener('change', (e) => {
  updateCities(e.target.value);
});

const showModal = () => {
  filterModal.classList.remove('hidden');
};

const hideModal = () => {
  filterModal.classList.add('hidden');
};

filterButton.addEventListener('click', showModal);
filterModal.addEventListener('click', (e) => {
  if (e.target === filterModal) {
    hideModal();
  }
});

cancelButton.addEventListener('click', (e) => {
  e.preventDefault();
  hideModal();
});

// Make handleFilterSubmit available globally
window.handleFilterSubmit = function() {
  const citySelect = document.querySelector('select[name="city[id]"]');
  const regionSelect = document.querySelector('select[name="region[id]"]');
  
  if (citySelect.value && citySelect.value !== '') {
    window.location.href = `/cities/${citySelect.value.toLowerCase()}`;
  } else if (regionSelect.value && regionSelect.value !== '') {
    window.location.href = `/regions/${regionSelect.value.toLowerCase()}`;
  }
}

updateCities();